import { SectionProvider } from '@/shared/providers/SectionContext';
import { SectionStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import clsx from 'clsx';
import { useMemo } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import styles from './Section.module.scss';

const Section = (props: SectionStoryblok) => {
    const theme = useMemo(() => {
        switch (props.blok.theme) {
            case 'Dark':
                return 'Dark';
            default:
                return 'Light';
        }
    }, [props.blok.theme]);
    return (
        <div
            id={props?.blok?.anchor || props?.blok?._uid}
            className={clsx(styles.root, { [styles.dark]: theme === 'Dark', [styles.light]: theme === 'Light' })}
            style={{
                paddingBottom: '128px',
                backgroundColor: `var(--${props?.blok.customBackground || 'standardBackground'}${theme})`,
                color: `var(--primaryTextColor${theme})`,
            }}
            {...storyblokEditable(props.blok)}
        >
            {props.blok.content?.map((nestedBlok: SbBlokData) => (
                <SectionProvider theme={theme} key={nestedBlok._uid}>
                    <SkeletonTheme
                        baseColor={theme === 'Dark' ? 'var(--core10)' : 'var(--core80)'}
                        highlightColor={theme === 'Dark' ? 'var(--core15)' : 'var(--core95)'}
                    >
                        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                    </SkeletonTheme>
                </SectionProvider>
            ))}
        </div>
    );
};
export default Section;
